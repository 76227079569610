.parent {
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
}

.footer {
    border-radius: 20px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: yellow;
}

.footer a {
    text-decoration: none;
    margin: 10px;
}

.navbar {
    border-radius: 20px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    background-color: yellow;
}

.logo {
    padding: 20px;
}

.menu {
    display: flex;
    align-items: center;
}

.menu ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.menu li {
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
    background:#D9325E;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
}

.menu li a {
    color: wheat;
    text-decoration: none;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 0px;
}

.gamecontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 0px;
}

.gamecontainer>div {
    background-color: black;
    color: white;
    border: solid 8px #68D82E;
    border-radius: 20px;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: 90vh;
    margin: 10px;
    overflow: hidden;
    padding: 15px;
}

.gamecontainer>div a {
    color: #68D82E;
    text-decoration: none;
}

.container>div {
    background-color: #f1f1f1;
    border-radius: 20px;
    width: 30vw;
    height: 30vh;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
    cursor: pointer;
    border: solid 8px #68D82E;
}

.container>div a {
    color: black;
    text-decoration: none;
    text-shadow: 2px 1px #ffffff;

}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border: 0;
}