:root {
  --bgcolor: #371464;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  font-family: 'Concert One', cursive;  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #371464;
  animation: animatedBackground 1000s linear infinite;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}